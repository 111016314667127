<template>
  <div>
    <h2 class="mt-6  text-uppercase text-lg font-bold">{{ label }}</h2>
    <md-table>
      <md-table-row>
        <md-table-head md-numeric>ID</md-table-head>
        <md-table-head>Name</md-table-head>
        <md-table-head>Email</md-table-head>
        <md-table-head>Gender</md-table-head>
        <md-table-head>Job Title</md-table-head>
      </md-table-row>
      <template v-for="i in 3">
        <md-table-row :key="i">
          <md-table-cell md-numeric>{{ i }}</md-table-cell>
          <md-table-cell>Shawna Dubbin</md-table-cell>
          <md-table-cell>sdubbin0@geocities.com</md-table-cell>
          <md-table-cell>Male</md-table-cell>
          <md-table-cell>Assistant Media Planner</md-table-cell>
        </md-table-row>
      </template>
    </md-table>
  </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        }
    }
};
</script>

<style></style>
